.analysis {
  .main {
    h2 {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }
}
.stats-box {
  background-color: #ffffff;
  background-clip: border-box;
  border: 0 solid rgba(0, 0, 0, 0.125);
  border-radius: 0.75rem;
  box-shadow: 0rem 0.25rem 0.375rem -0.0625rem rgba(0, 0, 0, 0.1),
    0rem 0.125rem 0.25rem -0.0625rem rgba(0, 0, 0, 0.06);
  padding: 24px 16px;
  max-height: 450px;
  background-color: #ffffff;
  canvas {
    margin: 0px auto;
  }
  h2 {
    font-size: 18px;
    margin-bottom: 10px;
  }
  h3 {
    font-size: 18px;
    display: none;
  }
}
.stats-filters {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  .form-select {
    max-width: 160px;
    font-size: 14px;
    background-color: #9787ff;
    color: #fff;
  }
  input::placeholder,
  input {
    color: #9787ff;
  }
  .rs-input-group-addon {
    svg {
      path {
        fill: #9787ff;
      }
    }
  }
  .btn-default {
    color: #9787ff;
    display: flex;
    align-items: center;
    background-color: #fff;
    border: 1px solid #d7dbec;
    margin: 0 10px;
    img {
      margin-right: 8px;
    }
  }
}
.questions-table {
  max-height: 100% !important;
  .questions-header {
    input {
      max-width: 244px;
    }
  }
  .table {
    thead {
      tr {
        th {
          border: none;
          color: #5a607f;
          font-weight: 400;
          font-size: 14px;
          &:last-child {
            text-align: right;
          }
        }
      }
    }
    tbody {
      tr {
        td {
          &:not(:first-child) {
            text-align: center;
          }
          &:last-child {
            text-align: right;
          }
          .view-btn {
            background-color: #9787ff;
            color: #fff;
            font-size: 10px;
            img {
              margin-right: 2px;
            }
          }
        }
      }
    }
  }
}
.pagination {
  display: flex;
  align-items: center;
  .btn-default {
    color: #5a607f;
    border-radius: 4px;
  }
  .btn-default:disabled {
    border: none;
  }
  .btn-default:focus {
    border: none;
    box-shadow: none;
  }
  .btn-default.active {
    border: none;
    background-color: #ecf2ff;
    color: #9787ff;
  }
}
.custom-select {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  padding: 8px 30px 8px 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: #fff;
  color: #333;
  font-size: 16px;
  cursor: pointer;
  max-width: 70px;
  margin: 10px 0;
  margin-left: auto;
  display: table;
}
.custom-line-chart {
  height: 300px !important;
  max-width: 100%;
}
.view-full-btns {
  display: flex;
  justify-content: flex-end;
  button {
    color: #9787ff;
    display: flex;
    align-items: center;
    background-color: #fff;
    border: 1px solid #d7dbec;
    margin: 0 10px;
    &:hover {
      background-color: #fff;
      color: #9787ff;
      border: 1px solid #d7dbec;
    }
  }
}
