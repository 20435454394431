.charts {
  .charts-by-month {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    padding-top: 30px;
    .chart-box {
      flex-basis: 32%;
      background-color: #fff;
      padding: 16px;
      border-radius: 0.75rem;
      position: relative;
      box-shadow: 0rem 0.25rem 0.375rem -0.0625rem rgba(0, 0, 0, 0.1),
        0rem 0.125rem 0.25rem -0.0625rem rgba(0, 0, 0, 0.06);
      .chart-box-month {
        background: linear-gradient(195deg, #66bb6a, #43a047);
        border-radius: 0.75rem;
        margin-top: -40px;
      }
      &:not(:last-child) {
        margin-right: 24px;
      }
    }
    .spinner-border {
      color: #ec407a;
      margin: 0px auto;
    }
  }

  .chart-box {
    flex-basis: 33%;
    background-color: #fff;
    padding: 16px;
    border-radius: 0.75rem;
    position: relative;
    .chart-box-week {
      background: linear-gradient(195deg, #ec407a, #d81b60);
      border-radius: 0.75rem;
      margin-top: -40px;
    }
    .chart-box-rating {
      background: linear-gradient(195deg, #42424a, #191919);
      border-radius: 0.75rem;
      margin-top: -40px;
    }
    &:not(:last-child) {
      margin-right: 24px;
    }
    h3 {
      font-size: 16px;
      color: #344767;
      font-weight: 700;
      margin-top: 24px;
      text-transform: capitalize;
    }
    p {
      font-size: 0.875rem;
      font-weight: 300;
      line-height: 1.5;
      text-transform: uppercase;
      letter-spacing: 0.02857em;
      text-decoration: none;
      color: #7b809a;
    }
    .divider {
      border-width: 0;
      border-style: solid;
      border-color: rgba(0, 0, 0, 0.12);
      border-bottom-width: thin;
      background-color: transparent;
      background-image: linear-gradient(
        to right,
        rgba(52, 71, 103, 0),
        rgba(52, 71, 103, 0.4),
        rgba(52, 71, 103, 0)
      ) !important;
      height: 0.0625rem;
      margin: 1rem 0;
      border-bottom: none;
      opacity: 0.25;
    }
  }
  .custom-select-wrapper {
    position: relative;
    display: inline-block;
  }

  .custom-select {
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    padding: 8px 30px 8px 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    background-color: #fff;
    color: #333;
    font-size: 16px;
    cursor: pointer;
    max-width: 150px;
    margin: 10px 0;
    margin-left: auto;
    display: table;
  }

  .custom-select::after {
    content: "\25BC";
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
    pointer-events: none;
  }

  /* Hover styles */
  .custom-select:hover {
    border-color: #aaa;
  }

  /* Focus styles */
  .custom-select:focus {
    border-color: #555;
    outline: none;
    box-shadow: none;
  }

  /* Disabled styles */
  .custom-select:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
}
