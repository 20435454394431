.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 5px 16px;
  margin-bottom: 30px;
  .header-breadcrumb {
    a {
      font-size: 0.875rem;
      color: #6c757d;
      &:hover {
        text-decoration: none;
      }
      span {
        font-size: 1rem;
        line-height: 1.625;
        color: #344767;
        font-weight: 700;
        letter-spacing: 0.0075em;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        opacity: 1;
        text-transform: capitalize;
        vertical-align: unset;
        -webkit-text-decoration: none;
        text-decoration: none;
        color: #344767;
        font-weight: 400;
      }
    }
    p {
      font-size: 1rem;
      line-height: 1.625;
      color: #344767;
      font-weight: 700;
      letter-spacing: 0.0075em;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      opacity: 1;
      text-transform: capitalize;
      vertical-align: unset;
      -webkit-text-decoration: none;
      text-decoration: none;
      color: #344767;
      font-weight: 700;
    }
  }
  .right-nav {
    display: flex;
    align-items: center;
    input {
      border: 1px solid #6c757d7c;
      height: 45px;
      background-color: transparent;
      border-radius: 8px;
      &:focus {
        box-shadow: none;
      }
    }
    input::placeholder {
      color: #344767;
      font-size: 14px;
    }
    .builder-btn {
      border: 1px solid #d81b60;
      color: #d81b60;
      height: 45px;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 265px;
      font-size: 16px;
      margin: 0 24px;
      border-radius: 8px;
    }
    .star-box {
      display: flex;
      align-items: center;
      border: 1px solid #6c757d7c;
      max-width: 140px;
      border-radius: 5px;
      margin-right: 24px;
      .star-btn {
        border-right: 1px solid #6c757d7c;
        padding: 3px 5px;
        border-radius: 0;
        display: flex;
        align-items: center;
        svg {
          height: 0.7em;
          width: 0.7em;
          margin-right: 3px;
        }
      }
      div {
        padding: 3px 5px;
        font-size: 16px;
        font-weight: 500;
      }
    }
  }
  .setting-box {
    display: flex;
    align-items: center;
    button {
      padding: 0;
      &:not(:last-child) {
        margin-right: 8px;
      }
      svg {
        fill: #6c757d;
      }
    }
  }
}
.header.sticky {
  position: sticky;
  z-index: 1100;
  top: 0;
  left: auto;
  right: 0;
  color: inherit;
  box-shadow: none;
  box-shadow: inset 0rem 0rem 0.0625rem 0.0625rem rgba(255, 255, 255, 0.9),
    0rem 1.25rem 1.6875rem 0rem rgba(0, 0, 0, 0.05);
  -webkit-backdrop-filter: saturate(200%) blur(1.875rem);
  backdrop-filter: saturate(200%) blur(1.875rem);
  background-color: rgba(255, 255, 255, 0.8);
  color: #344767;
  top: 0.75rem;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 0.75rem;
}
