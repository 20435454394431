.employees {
  .main {
    h2 {
      display: flex;
      align-items: center;
      justify-content: space-between;
      button {
        background-color: #fff;
        border-radius: 4px;
        color: #9787ff;
        font-size: 14px;
        display: flex;
        align-items: center;
        img {
          margin-right: 8px;
        }
      }
    }
  }
  .questions-table {
    max-height: 100% !important;
    .questions-header {
      input {
        max-width: 244px;
      }
    }
    .table {
      thead {
        tr {
          th {
            border: none;
            color: #5a607f;
            font-weight: 400;
            font-size: 14px;
            &:last-child {
              text-align: right;
            }
          }
        }
      }
      tbody {
        tr {
          td {
            &:not(:first-child) {
              text-align: center;
            }
            &:last-child {
              text-align: right;
            }
            .view-btn {
              background-color: #9787ff;
              color: #fff;
              font-size: 10px;
              img {
                margin-right: 2px;
              }
            }
          }
        }
      }
    }
  }
  .pagination {
    display: flex;
    align-items: center;
    .btn-default {
      color: #5a607f;
      border-radius: 4px;
    }
    .btn-default:disabled {
      border: none;
    }
    .btn-default:focus {
      border: none;
      box-shadow: none;
    }
    .btn-default.active {
      border: none;
      background-color: #ecf2ff;
      color: #9787ff;
    }
  }
  .custom-select-wrapper {
    position: relative;
    display: inline-block;
  }

  .custom-select {
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    padding: 8px 30px 8px 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    background-color: #fff;
    color: #333;
    font-size: 16px;
    cursor: pointer;
    max-width: 70px;
    margin: 10px 0;
    margin-left: auto;
    display: table;
  }

  .custom-select::after {
    content: "\25BC";
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
    pointer-events: none;
  }

  /* Hover styles */
  .custom-select:hover {
    border-color: #aaa;
  }

  /* Focus styles */
  .custom-select:focus {
    border-color: #555;
    outline: none;
    box-shadow: none;
  }

  /* Disabled styles */
  .custom-select:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
}
.custom-modal {
  text-align: center;
  .modal-header {
    border-bottom: none;
  }
  .modal-body {
    padding-bottom: 80px;
    h3 {
      margin: 30px 0 10px;
      font-size: 18px;
      font-weight: 500;
    }
    p {
      color: #5a607f;
      font-size: 18px;
      font-weight: 500;
    }
    button {
      margin-top: 20px;
      width: 150px;
      background-color: #fff;
      border: 1px solid #d0d5dd;
      color: #000;
    }
  }
}
.notes {
  background-color: #fff;
  border-radius: 25px;
  margin-top: 30px;
  padding: 10px 20px;
  max-height: 332px;
  overflow-y: scroll;
  &::-webkit-scrollbar {
    display: none;
  }
  h3 {
    font-size: 18px;
    color: #000;
  }
  > p {
    text-align: right;
  }
  .notes-box {
    border: 1px solid #e4e4e7;
    margin: 20px 0;
    border-radius: 12px;
    padding: 15px;
    p {
      font-size: 14px;
      color: #5b5b5b;
    }
  }
  .date-time {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    p {
      margin-bottom: 0;
      margin-top: 0;
      display: flex;
      align-items: center;
      svg {
        margin-right: 5px;
      }
      &:first-child {
        margin-right: 15px;
      }
    }
  }
}
.custom-badge {
  background-color: #9787ff;
  color: #fff;
  font-size: 14px;
  font-weight: 400;
  padding: 3px 5px;
  border-radius: 3px;
  margin-left: 5px;
}
