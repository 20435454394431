.latest-reviews {
  background-color: #ffffff;
  background-clip: border-box;
  border: 0 solid rgba(0, 0, 0, 0.125);
  border-radius: 5px;
  box-shadow: 0rem 0.25rem 0.375rem -0.0625rem rgba(0, 0, 0, 0.1),
    0rem 0.125rem 0.25rem -0.0625rem rgba(0, 0, 0, 0.06);
  padding: 24px 16px;

  .questions-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    h5 {
      font-size: 18px;
      color: #000;
      font-weight: 700;
    }
    p {
      color: #344767;
      font-size: 14px;
      margin-bottom: 0;
      font-weight: 400;
      svg {
        fill: #1a73e8;
      }
    }
    margin-bottom: 24px;
  }
  > div {
    max-height: 380px;
    overflow-y: scroll;
    &::-webkit-scrollbar {
      display: none;
    }
    .reviews-list {
      li {
        font-size: 0.875rem;
        font-weight: 300;
        line-height: 1.5;
        text-transform: uppercase;
        letter-spacing: 0.02857em;
        opacity: 1;
        text-transform: none;
        vertical-align: unset;
        -webkit-text-decoration: none;
        text-decoration: none;
        color: #344767;
        font-weight: 600;
        p {
          font-size: 0.75rem;
          font-weight: 300;
          line-height: 1.25;
          letter-spacing: 0.03333em;
          opacity: 1;
          text-transform: none;
          vertical-align: unset;
          -webkit-text-decoration: none;
          text-decoration: none;
          color: #7b809a;
          margin-bottom: 0;
        }
        &:not(:last-child) {
          margin-bottom: 24px;
        }
      }
    }
  }
}
.questions-table {
  background-color: #ffffff;
  -webkit-background-clip: border-box;
  background-clip: border-box;
  border: 0 solid rgba(0, 0, 0, 0.125);
  border-radius: 5px;
  box-shadow: 0rem 0.25rem 0.375rem -0.0625rem rgba(0, 0, 0, 0.1),
    0rem 0.125rem 0.25rem -0.0625rem rgba(0, 0, 0, 0.06);
  padding: 24px 16px;
  max-height: 380px;
  overflow-x: scroll;

  .table {
    overflow-x: scroll;
  }
  .questions-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    h5 {
      font-size: 18px;
      color: #000;
      font-weight: 700;
    }
    p {
      color: #344767;
      font-size: 14px;
      margin-bottom: 0;
      font-weight: 400;
      svg {
        fill: #1a73e8;
      }
    }
    margin-bottom: 24px;
  }
  .table {
    thead {
      tr {
        th {
          border: none;
          text-align: left;
          opacity: 0.7;
          background: transparent;
          color: #5a607f;
          border-radius: none;
          box-shadow: none;
          font-size: 0.65rem;
          font-weight: 700;
          text-transform: uppercase;
          min-width: 170px;
          &:not(:first-child) {
            text-align: center;
          }
        }
      }
    }
    tbody {
      tr {
        td {
          border-right: none;
          border-left: none;
          font-size: 0.875rem;
          font-weight: 300;
          line-height: 1.5;
          text-transform: uppercase;
          letter-spacing: 0.02857em;
          padding: 15px 0;
          line-height: 1;
          opacity: 1;
          text-transform: none;
          vertical-align: unset;
          -webkit-text-decoration: none;
          text-decoration: none;
          color: #131523;
          font-weight: 500;
          text-align: left;
          &:not(:first-child) {
            text-align: center;
          }
        }
      }
    }
  }
  .reviews-list {
    li {
      font-size: 0.875rem;
      font-weight: 300;
      line-height: 1.5;
      text-transform: uppercase;
      letter-spacing: 0.02857em;
      opacity: 1;
      text-transform: none;
      vertical-align: unset;
      -webkit-text-decoration: none;
      text-decoration: none;
      color: #344767;
      font-weight: 600;
      p {
        font-size: 0.75rem;
        font-weight: 300;
        line-height: 1.25;
        letter-spacing: 0.03333em;
        opacity: 1;
        text-transform: none;
        vertical-align: unset;
        -webkit-text-decoration: none;
        text-decoration: none;
        color: #7b809a;
        margin-bottom: 0;
      }
      &:not(:last-child) {
        margin-bottom: 24px;
      }
    }
  }
}
