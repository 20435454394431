.reviews {
  .add-new {
    color: #fff;
    background-color: #ec407a;
    margin-left: auto;
    display: table;
    margin-bottom: 30px;
    margin-top: 20px;
    &:hover,
    &:focus {
      color: #fff;
      background-color: #ec407a;
    }
  }
  h2 {
    font-size: 25px;
    color: #000;
    margin-bottom: 25px;
  }
  .all-reviews {
    display: flex;
    align-items: center;
    flex-wrap: wrap;

    .survey-box {
      background-color: #fff;
      height: 242px;
      width: 243px;
      box-shadow: 0rem 0.25rem 0.375rem -0.0625rem rgba(0, 0, 0, 0.1),
        0rem 0.125rem 0.25rem -0.0625rem rgba(0, 0, 0, 0.06);
      border-radius: 15px;
      &:not(:last-child) {
        margin-right: 25px;
      }
      margin-bottom: 25px;
      display: flex;
      align-items: center;
      justify-content: center;

      .survey-cont {
        text-align: center;
        width: 100%;
        padding: 0 20px;
        a {
          display: block;
          font-size: 18px;
          margin: 25px 0 8px;
          font-size: 18px;
          color: #111827;
          text-transform: capitalize;
          img {
            height: 37px;
            width: 37px;
          }
        }
        .survey-title {
          min-height: 51px;
        }
        .create-icon {
          height: 55px;
          width: 57px;
        }
        .survey-edit {
          display: flex;
          align-items: center;
          justify-content: space-between;
          p {
            font-size: 12px;
            color: grey;
            margin-bottom: 0;
          }
          button {
            padding: 0;
            img {
              height: 24px;
              width: 24px;
            }
          }
        }
      }
    }
  }
  .feedback-box {
    background-color: #fff;
    box-shadow: 0rem 0.25rem 0.375rem -0.0625rem rgba(0, 0, 0, 0.1),
      0rem 0.125rem 0.25rem -0.0625rem rgba(0, 0, 0, 0.06);
    border-radius: 15px;
    padding: 20px 20px;
    margin-bottom: 25px;
    label {
      color: #202124;
      font-size: 18px;
      margin-bottom: 10px;
    }
    input {
      height: 58px;
      &:disabled {
        background-color: transparent;
      }
    }
    .add-question {
      display: flex;
      width: 100%;
      justify-content: flex-end;
      button {
        &:first-child {
          border: 1px solid #9686fe;
          color: #9686fe;
          height: 45px;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 16px;
          margin: 0 24px;
          border-radius: 8px;
        }
        &:nth-child(2) {
          background-color: #9686fe;
          color: #fff;
        }
      }
    }
  }
  .question-box {
    h2 {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }
  .edit-survey {
    h2 {
      display: flex;
      align-items: center;
      justify-content: space-between;
      button {
        height: 48px;
        border-radius: 30px;
        color: #fff;
        font-size: 16px;
        padding: 0 20px;
        &:nth-child(2) {
          background-color: #9686fe;
        }
        img {
          margin-right: 8px;
        }
      }
    }
  }
  .survey-edit {
    h3 {
      font-size: 14px;
      font-weight: 400;
      img {
        margin-right: 10px;
      }
    }
  }
}
