.top-ratings {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
  padding-top: 24px;
  .ratings-box {
    position: relative;
    min-width: 0;
    word-wrap: break-word;
    background-color: #ffffff;
    -webkit-background-clip: border-box;
    background-clip: border-box;
    border: 0 solid rgba(0, 0, 0, 0.125);
    border-radius: 0.75rem;
    box-shadow: 0rem 0.25rem 0.375rem -0.0625rem rgba(0, 0, 0, 0.1),
      0rem 0.125rem 0.25rem -0.0625rem rgba(0, 0, 0, 0.06);
    overflow: visible;
    flex-basis: 23.5%;
    padding: 0 16px;
    min-height: 100px;
    &:not(:last-child) {
      margin-right: 24px;
    }
    &:nth-child(2) {
      .ratings-box-header {
        .icon-box {
          background: linear-gradient(195deg, #ec407a, #d81b60);
        }
      }
    }
    &:nth-child(3) {
      .ratings-box-header {
        .icon-box {
          background: linear-gradient(195deg, #66bb6a, #43a047);
        }
      }
    }
    &:nth-child(4) {
      .ratings-box-header {
        .icon-box {
          background: linear-gradient(195deg, #49a3f1, #1a73e8);
        }
      }
    }
    .ratings-box-header {
      display: flex;
      justify-content: space-between;
      padding: 8px 0 0;
      .icon-box {
        width: 4rem;
        height: 4rem;
        margin-top: -24px;
        opacity: 1;
        background: linear-gradient(195deg, #42424a, #191919);
        color: #ffffff;
        border-radius: 0.75rem;
        box-shadow: 0rem 0.25rem 1.25rem 0rem rgba(0, 0, 0, 0.14),
          0rem 0.4375rem 0.625rem -0.3125rem rgba(64, 64, 64, 0.4);
        display: flex;
        justify-content: center;
        align-items: center;
      }
      div {
        &:nth-child(2) {
          p {
            font-size: 0.875rem;
            font-weight: 300;
            line-height: 1.5;
            text-transform: uppercase;
            letter-spacing: 0.02857em;
            opacity: 1;
            text-transform: none;
            vertical-align: unset;
            -webkit-text-decoration: none;
            text-decoration: none;
            color: #7b809a;
            font-weight: 300;
            margin-bottom: 0;
          }
          h2 {
            font-size: 1.5rem;
            line-height: 1.375;
            font-family: "Roboto", "Helvetica", "Arial", sans-serif;
            color: #344767;
            font-weight: 700;
            letter-spacing: 0.00735em;
            opacity: 1;
            text-transform: none;
            vertical-align: unset;
            -webkit-text-decoration: none;
            text-decoration: none;
            color: #344767;
            text-align: right;
          }
        }
      }
    }
    h5 {
      font-size: 0.875rem;
      font-weight: 300;
      line-height: 1.5;
      text-transform: uppercase;
      letter-spacing: 0.02857em;

      display: flex;
      opacity: 1;
      text-transform: none;
      vertical-align: unset;
      -webkit-text-decoration: none;
      text-decoration: none;
      color: #7b809a;
    }
  }
}
.divider {
  border-width: 0;
  border-style: solid;
  border-color: rgba(0, 0, 0, 0.12);
  border-bottom-width: thin;
  background-color: transparent;
  background-image: linear-gradient(
    to right,
    rgba(52, 71, 103, 0),
    rgba(52, 71, 103, 0.4),
    rgba(52, 71, 103, 0)
  ) !important;
  height: 0.0625rem;
  margin: 1rem 0;
  border-bottom: none;
  opacity: 0.25;
}
@media (max-width: 1280px) {
  .top-ratings .ratings-box {
    flex-basis: 45%;
    margin-bottom: 30px;
  }
}
