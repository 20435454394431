.login-main {
  display: flex;

  .login-aside {
    background-image: url("../images/layout-component.png");
    background-size: cover;
    background-repeat: no-repeat;
    height: 100vh;
    width: 572px;
    max-width: 572px;
    padding: 40px 50px;
    h5 {
      font-size: 26px;
      color: #fff;
      font-weight: 600;
      margin: 70px 0 20px;
    }
    p {
      color: #fff;
      font-size: 16px;
    }
  }
  .login-cont {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    min-width: 0;
    word-wrap: break-word;
    background-color: #ffffff;
    -webkit-background-clip: border-box;
    background-clip: border-box;
    border: 0 solid rgba(0, 0, 0, 0.125);
    border-radius: 0.75rem;
    box-shadow: 0rem 0.25rem 0.375rem -0.0625rem rgba(0, 0, 0, 0.1),
      0rem 0.125rem 0.25rem -0.0625rem rgba(0, 0, 0, 0.06);
    overflow: visible;
    padding: 80px 30px;
    width: 100%;
    > div {
      width: 520px;
      max-width: 520px;
      h2 {
        color: #111827;
        font-size: 30px;
        text-align: center;
        font-weight: 600;
      }
      h3 {
        color: #111827;
        font-size: 16px;
        text-align: center;
        margin-bottom: 50px;
        font-weight: 400;
      }
    }

    form {
      label {
        color: #111827;
        font-size: 16px;
        margin-bottom: 0;
        font-weight: 400;
        sup {
          color: #ff0000;
        }
      }
      input {
        margin-bottom: 25px;
        background-color: #fff;
      }
      .show-hide-pass {
        display: flex;
        position: relative;
        svg {
          position: absolute;
          right: 10px;
          top: 7px;
          cursor: pointer;
          path {
            fill: #292d32;
          }
        }
      }
      button {
        font-size: 0.75rem;
        font-weight: 700;
        border-radius: 0.5rem;
        padding: 0.393875rem 1.03775rem rem;
        line-height: 1.4;
        text-align: center;
        text-transform: uppercase;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        transition: all 150ms ease-in;
        min-height: 2.5rem;
        color: #7b809a;
        padding: 0.625rem 1.5rem;
        background-color: #9686fe;
        color: #ffffff;
        width: 100%;
        margin-top: 32px;
        border-radius: 8px;
      }
    }
    p {
      font-size: 0.875rem;
      font-weight: 300;
      line-height: 1.5;
      text-transform: uppercase;
      letter-spacing: 0.02857em;
      opacity: 1;
      text-transform: none;
      vertical-align: unset;
      -webkit-text-decoration: none;
      text-decoration: none;
      color: #7b809a;
      text-align: center;
      margin-top: 24px;
      a {
        color: #9686fe;
        font-weight: 700;
      }
    }
    .error {
      background-color: #d11a2a;
      color: #fff;
      font-size: 14px;
      border-radius: 30px;
      padding: 8px 8px;
      font-size: 14px;
    }
  }
}
