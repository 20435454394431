.sidebar {
  box-shadow: 0rem 1.25rem 1.6875rem 0rem rgba(0, 0, 0, 0.05);
  margin-bottom: inherit;
  width: 250px;
  -webkit-transform: translateX(0);
  -moz-transform: translateX(0);
  -ms-transform: translateX(0);
  transform: translateX(0);
  background: #000000;
  position: fixed;
  height: 100vh;
  overflow: hidden;
  padding: 0.09375rem 1rem;
  left: 0;

  .sidebar-logo {
    padding: 24px 0 8px 0;
    text-align: center;
    a {
      h6 {
        color: #fff;
      }
    }
  }
  .sidebar-divider {
    height: 0.0625rem;
    margin: 1rem 0;
    border-bottom: none;
    opacity: 0.25;
    background-color: transparent;
    background-image: linear-gradient(
      to right,
      rgba(255, 255, 255, 0),
      #ffffff,
      rgba(255, 255, 255, 0)
    ) !important;
    overflow: hidden;
  }
  .sidebar-list {
    height: 100%;

    li {
      border-radius: 0.375rem;
      margin-bottom: 20px;
      a {
        font-size: 14px;
        color: #fff;
      }
      &:hover {
        background-color: #9686fe;
        color: #fff;
      }
    }
    .nav-item.active {
      background-color: #9686fe;
    }
    .logout-btn {
      background-color: #9686fe;
      color: #fff;
      position: absolute;
      bottom: 20px;
      max-width: 100%;
      width: 210px;
      text-align: left;
      img {
        margin-right: 8px;
      }
    }
  }
}
